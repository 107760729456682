@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

html {
  font-family: Poppins, Roboto, 'Segoe UI', sans-serif;
}

.ReactCollapse--collapse {
  transition: height 100ms;
}

.ml-section-list {}

.ml-section {
  margin: 0.5rem;
}

.ml-card {
  background-color: #ffffff;
  box-shadow:
    0 0.1rem 0.3rem -0.1rem rgba(0, 0, 0, 0.24),
    0 0.1rem 0.1rem -0.1rem rgba(0, 0, 0, 0.12);
  border-radius: 0.2rem;
}

.ml-card-header {
  background-color: #edf3f6;
  border-radius: 0.2rem 0.2rem 0 0;
}

.ml-card-header-content {
  font-weight: 400;
  color: #192a3e;
  padding: 0.5rem 1rem;
}

.ml-card-content {
  padding: 0.5rem;
}

.ml-form {
  display: block;
  margin: 0;
  padding: 0;
}

.ml-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ml-list-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #eeeeee;
  overflow-x: auto;
  overflow-y: hidden;
}

.ml-list-item:first-of-type {
  border-top: 0;
}

.ml-list-item-icon {
  flex: 0 0 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #839ab4;
}

.ml-list-item-content {
  flex: 1 1 auto;
  padding: 0.5rem;
}

.ml-list-item-primary {
  font-weight: 400;
  color: #364556;
}

.ml-list-item-secondary {
  font-weight: 200;
  color: #a1a1a1;
}

.ml-list-item-action {
  flex: 0 0 3rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
}

.ml-field-container {
  margin: 0;
  border: 0;
  padding: 0.5rem;
  background: white;
}

.ml-field-label {
  font-weight: 400;
  color: #364556;
}

.ml-field-content {}

.ml-input {
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0.5rem;
  border: 1px solid #839ab4;
  background-color: #ffffff;
  border-radius: 0.2rem;
  width: 100%;
  box-sizing: border-box;
  font: inherit;
}

.ml-input[readonly] {
  border: 1px solid #b5babe;
  background-color: #e5ebf1;
}

.ml-input:disabled {
  border: 1px solid #b5babe;
  background-color: #e5ebf1;
}

.ml-dialogheader-container {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  z-index: 100;

  background-color: #ffffff;
  border-bottom: 4px solid #009a44;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.ml-dialogheader-center {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-dialogheader-button {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-dialogheader-text {
  font-weight: 300;
  font-size: 1.5rem;
  letter-spacing: -0.05em;
  color: #6a707e;
}

.ml-toolbar-container {
  box-sizing: border-box;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;

  background-color: #009a44;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
}

.ml-toolbar-backward {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-toolbar-center {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-toolbar-forward {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.ml-button {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0.5rem;
  border-radius: 0.2rem;
  height: 3rem;
  cursor: pointer;

  background-color: #ffffff00;
  background-color: var(--color);
  color: #ffffff;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.ml-button:hover {
  filter: brightness(80%);
}

.ml-button:active {
  filter: brightness(60%);
}

.ml-icon-button {
  box-sizing: border-box;
  border: 1px solid #6a707e;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  background-color: #ffffff;
  border-color: var(--color);
  color: var(--color);
}

.ml-icon-button:hover {
  filter: brightness(80%);
}

.ml-icon-button:active {
  filter: brightness(60%);
}

.ml-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml-caption-container {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  padding: 0.5rem;
  align-items: center;
}

.ml-caption-icon {
  color: #6a707e;
}

.ml-caption-label {
  color: #6a707e;
}

.ml-alert-container {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1.5rem;
  align-items: center;
  background-color: #9a0000;
  border-top: 1px solid #200000;
  border-bottom: 1px solid #200000;
}

.ml-alert-icon {
  display: flex;
  color: #ffffff;
  align-self: flex-start;
}

.ml-alert-label {
  color: #ffffff;
  overflow: auto;
}

.ml-card-content .ml-alert-container {
  margin: 0 -0.5rem;
}

.ml-counter-container {
  display: flex;
  align-items: center;
}

.ml-counter-content {
  flex: 1 1 auto;
}

.ml-counter-count {
  flex: 0 0 auto;
  background-color: #6a707e;
  color: #ffffff;
  border-radius: 0.5rem;

  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml-count-success {
  background-color: #009a44;
}

.ml-count-fail {
  background-color: #d68100;
  margin: 0 0.5rem;
}

.ml-counterbadge-container {
  display: grid;
}

.ml-counterbadge-content {
  grid-area: 1/1;
}

.ml-counterbadge-count {
  grid-area: 1/1;
  justify-self: flex-end;
  align-self: flex-end;

  background-color: #009a44;
  font-size: 0.7rem;
  color: #ffffff;
  border-radius: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0.2rem, -0.2rem);
}

.ml-required {
  color: #9a0000;
  font-weight: 500;
  line-height: 0;
  vertical-align: middle;
  font-size: 1.5rem;
}

.ml-error-flash {
  animation: error-flash 3s;
}

@keyframes error-flash {
  50% {
    background-color: #e79c2bbe;
  }
}

.ml-status-text {
  font-weight: 600;
  vertical-align: middle;
}

.ml-status-text--good {
  color: #009a44;
}

.ml-status-text--bad {
  color: #d68100;
}

.ml-status-text--neutral {
  color: #6a707e;
}

.ml-flash-alert-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  font-size: 1.3rem;
}

.ml-flash-alert-warning {
  background-color: #e6980c;
  border-radius: 6px;
  color: white;
  margin: 10px;
  position: sticky;
  top: 0;
  right: 0;
  text-align: center;
  padding: 0.4em;
}

.ml-flash-alert-success {
  background-color: #32cd32;
  border-radius: 6px;
  color: white;
  margin: 10px;
  position: sticky;
  top: 0;
  right: 0;
  text-align: center;
}

.ml-tag {
  border-radius: 1em;
  border: 0.1em solid #6a707e;
  padding: 0.2em 1em;
  background: white;
}

.ml-search {
  margin-bottom: 0.5rem;
}

.ml-combobox-list {
  display: none;
  border: 1px solid #839ab4;
  background-color: #ffffff;
  border-radius: 0.2rem;
  max-height: 30rem;
  overflow-y: scroll;
}

.ml-environment {
  color: rgba(0, 0, 0, 0.54);
  border-right: 1px solid rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  display: block;
  line-height: 1em;
  padding-right: 0.8rem;
  margin-right: 0.8rem;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-field-title {
  color: black;
  font-weight: 350;
  line-height: 1.2rem;
}

.form-field-title-disabled {
  color: #e0e0e0;
  font-weight: 350;
  line-height: 1.2rem;
}

.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: black;
}

.trackingdetail-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  padding: 1rem;
  line-height: 1.5rem;
  color: rgb(17, 17, 17);
  border-top: 1px #ccd1d4 solid;
  background-color: #f9f9f9;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.trackingdetail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 0.5rem;
}

.trackingdetail-row:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .trackingdetail-card {
    font-size: 14px;
    padding: 0.75rem;
    margin: 10px;
  }

  .trackingdetail-row {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .trackingdetail-row:last-child {
    margin-bottom: 0;
  }
}

.trackingdetailhistory-card {
  max-width: flex;
  display: flex;
  justify-content: space-between;
  margin: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  overflow-x: auto;
}

.tracking-table {
  width: 100%;
  border-collapse: collapse;
  margin-left: 0;
  table-layout: auto;
  word-wrap: break-word;
}

.tracking-table th,
.tracking-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tracking-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #000;
}

.tracking-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tracking-table tr:hover {
  background-color: #f1f1f1;
}