@import url('~material-icons-font/material-icons-font.css');

html {
  background-color: #eeeeee;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100%;
}

body,
#root,
.mprise-mui {
  margin: 0;
  height: 100%;
}

input {
  font: inherit;
}

.material-icons {
  font-size: 1.5rem;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .content {
  flex: 1 1 auto;
}

.box .footer {
  flex: 0 1 40px;
}